import { Button } from '@material-ui/core';
import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ContactButton = () => {
    const scrollTo = (id: string):void => {
        setTimeout(() => {
            const element: any = document.querySelector(`#${id}`);
            if (!element) {
                return;
            }

            window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
        });
    };

    return (
        <AnchorLink to="/#contact-form" title="Our team">
            <Button color="primary" variant="contained" size="large">
                Contact us
            </Button>
        </AnchorLink>

    );
};

export default ContactButton;